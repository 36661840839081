import { Box, Modal, Fade, Backdrop, Typography, Button } from "@mui/material";
import ModalBox from  "./ModalBox";

import { EMISSIONS_BY_COST_CENTRE_ENDPOINT } from "../../util/Constants"
import { transformCostCentresToCSV } from "../../lib/ExportHelper"
import ExportButton from "../ExportButton/ExportButton"
import { useQueryEndpoint } from "../../hooks/QueryHooks"


const ExportCostCentresButtonControlWithQuery = ({ queryParams, onCancelClose }) => {

	const { data, loaded, error } = useQueryEndpoint(EMISSIONS_BY_COST_CENTRE_ENDPOINT, null, "GET", {
		...queryParams
	})

	const returnedData = data?.data?.costCentreGroups

	let totalRows = returnedData?.length
	let modalText = `${totalRows} rows of data for ${queryParams.useActivityDate ? "activities" : "transactions"} dates from ${queryParams.dateFrom} to ${queryParams.dateTo}`
	
	return (
		<>
		{loaded && 
			<Box>
				{modalText}
			</Box>
		}
			<ExportButton
				fileType="text/csv"
				fileExtension="csv"
				transformData={transformCostCentresToCSV}
				loading={!loaded || error}
				data={returnedData}
				fileName={`Emissions By Cost Centre (${queryParams.dateFrom})-(${queryParams.dateTo})`}
				onCancelClose={onCancelClose}
			/>
		</>
	)
}

export const ExportCostCentresModal = ({ open, handleClose, rawData, queryParams }) => {

    return (
        <ModalFormWrapper
            modalTitle="Export Cost Centre data"
            onCancelClose={handleClose}
            modalFlag={open}
            rawData={rawData}
            queryParams={queryParams}
        >
            <Box
                data-testid="add-product"
                className="display-grid grid-gap-1"
            >
            </Box>
        </ModalFormWrapper>
    );
}

export const ModalFormWrapper = ({ modalTitle = "TestX", onCancelClose,  modalFlag = false, rawData, queryParams, children}) => {
    const testid = modalTitle.replace(/ /g, '-').toLowerCase();

    return (
        <Modal
			data-testid={`${testid}-modal`}
			open={modalFlag}
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
            <Fade in={modalFlag}>
                <ModalBox style={{ maxWidth: "450px", width: "100%" }}>
                    <Box>
						<Typography data-testid={`${testid}-modal-title`} variant="h5" fontWeight={600}>
							{modalTitle}
						</Typography>
					</Box>
                    {children}
                    <ExportCostCentresButtonControlWithQuery rawData={rawData} fileName={`Emissions Log`} queryParams={queryParams} onCancelClose={onCancelClose} />
					<Box className="align-self-end flex-row gap-1">
						<Button
							data-testid={`${testid}-close-button`}
							className="btn-outlined-primary pl-2 pr-2"
							onClick={onCancelClose}
						>
							Cancel
						</Button>
					</Box>
                </ModalBox>
            </Fade>              
        </Modal>
    );
};