import { useQueryEndpoint } from "../hooks/QueryHooks"
import { CACHE_WARMUP_ENDPOINT, LATEST_API_VERSION } from "./Constants"
import { setQueryParameters } from "../pages/sub-components/SetQueryParameters"
import { getThisLastDateRange } from "./util"
import { useFilterAtomValue } from "../hooks/FilterHooks"
import LoadingWrapper from "../components/Loading/LoadingWrapper"
import LoadingSpinner from "../components/Loading/LoadingSpinner"

export const WarmCache = ({setInitialized}) => {
	const params = setQueryParameters()
	const filterDataValue = useFilterAtomValue()
	const dateRangeCalc = getThisLastDateRange(filterDataValue)
	setInitialized(false)

	const { error, loaded, isFetching } = useQueryEndpoint(CACHE_WARMUP_ENDPOINT, null, "POST", {
		"api-version": LATEST_API_VERSION,
		...params,
		dateFrom: dateRangeCalc.this.dateFrom,
		dateTo: dateRangeCalc.this.dateTo,
		previousDateFrom: dateRangeCalc.last.dateFrom,
		previousDateTo: dateRangeCalc.last.dateTo
	})

	if (loaded && !error) {
		setInitialized(true)
	}

    return (
        <>
            {isFetching && <h2>Fetching data, please hold...</h2> }
            <LoadingWrapper loaded={loaded} error={error} loadingComponent={<LoadingSpinner margin={0} />}></LoadingWrapper>
        </>
    )
}