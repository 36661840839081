// Surface errors to the UI here.  This would be a good place to send said errors to server side logging when implemented
export const ErrorHandling = (error, logging) => {

    if (Array.isArray(logging)) {
        logging.forEach(e => console.log(e));
    } else if (logging) {
        console.log(logging)
    }
    // TODO : handle this component, it does not display this error
    return error ?? null
}