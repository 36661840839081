import { Alert } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import * as React from "react"
import styles from "./index.module.scss"

const LoadingWrapper = ({ loaded, error, children, loadingComponent, errorComponent }) => {
	const defaultError = errorComponent ? (
		errorComponent
	) : (
		<Alert severity="error" data-testid={"error-component"} className={`${styles["errorMessage"]}`}>
			{error}
		</Alert>
	)
	const defaultLoading = loadingComponent ? loadingComponent : <CircularProgress data-testid={"circular-progress"} />

	return loaded ? (error ? defaultError : children) : defaultLoading
}

export default LoadingWrapper
